import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, ViewChild, } from '@angular/core'
import { Schedule } from '@app/shared'
import { Store } from '@ngrx/store'
import { Subject, takeUntil, tap } from 'rxjs'
import * as fromCoreStore from '@app/core/store'
import { OpenModal } from '@app/modals/store'
import moment from 'moment'
import { NgxPopperjsTriggers, NgxPopperjsPlacements, NgxPopperjsContentComponent } from 'ngx-popperjs'

@Component({
    selector: 'app-schedule-calendar',
    templateUrl: './schedule-calendar.component.html',
    styleUrls: ['./schedule-calendar.component.scss'],
})
export class ScheduleCalendarComponent implements OnInit, OnDestroy {
    NgxPopperjsTriggers = NgxPopperjsTriggers
    NgxPopperjsPlacements = NgxPopperjsPlacements
    @ViewChild('schedulePopover', { static: false }) schedulePopover: NgxPopperjsContentComponent
    requests = []
    filteredRequests = []
    filters
    @Input()
    total
    @Input()
    currentUser
    @Input()
    loading = false
    @Input()
    equipments
    @Output()
    onUpdate = new EventEmitter<any>()
    limit = 25
    activeHover = null
    stageTypes = [
        { name: 'All', key: 'All', count: 0 },
        { name: 'New', key: 'New', count: 0 },
        { name: 'Active', key: 'Active', count: 0 },
        { name: 'On-Hold', key: 'On-Hold', count: 0 },
        { name: 'Reviewed', key: 'Reviewed', count: 0 },
        { name: 'Contacting', key: 'Contacting', count: 0 },
        { name: 'Hired', key: 'Hired', count: 0 },
        { name: 'Rejected', key: 'Rejected', count: 0 }
    ]

    destroyed$ = new Subject<boolean>()
    days = []
    hovededSchedule = null

    constructor(private store: Store<fromCoreStore.State>, private cdr: ChangeDetectorRef) { }

    ngOnInit() {

        this.store.select(fromCoreStore.selectScheduleFilter).pipe(
            takeUntil(this.destroyed$),
            tap(filters => {

                if (!filters?.startDateCalendar) {
                    filters['startDateCalendar'] = moment().startOf('month').utcOffset(0, true).format()
                }

                if (!filters?.endDateCalendar) {
                    filters['endDateCalendar'] = moment().endOf('month').utcOffset(0, true).format()
                }

                if (!filters?.subType) {
                    filters['subType'] = 'Drivers'
                }

                this.filters = filters

                let f = {
                    ...filters
                }

                delete f?.rangeType
                delete f?.dispatcher
                delete f?.driver
                delete f?.limit
                delete f?.sort
                delete f?.page
                delete f?.unit
                delete f?.dir

                f['viewType'] = 'CALENDAR'

                this.store.dispatch(new fromCoreStore.GetCalendar(f))
            })
        ).subscribe()


        this.store.select(fromCoreStore.selectCalendarCombined).pipe(
            takeUntil(this.destroyed$),
            tap(data => {
                if (data) {
                    this.requests = []
                    this.filteredRequests = []
                    this.days = data?.days
                    let localRequests: any = data?.requests

                    for (let item of localRequests) {
                        if (this.filters?.subType == 'Trucks') {
                            if (item?._local?.unit && !this.requests?.includes(`${item?._local?.unit?.details} ${item?._local?.unit?.year} ${item?._local?.unit?.make} ${item?._local?.unit?.model}`)) {
                                this.requests.push(`${item?._local?.unit?.details} ${item?._local?.unit?.year} ${item?._local?.unit?.make} ${item?._local?.unit?.model}`)
                                this.filteredRequests.push(`${item?._local?.unit?.details} ${item?._local?.unit?.year} ${item?._local?.unit?.make} ${item?._local?.unit?.model}`)
                            }
                        } else {
                            if (!this.requests?.includes(item?._local?.driver?.name)) {
                                this.requests.push(item?._local?.driver?.name)
                                this.filteredRequests.push(item?._local?.driver?.name)
                            }
                        }
                    }
                    this.cdr.detectChanges()
                }
            })
        ).subscribe()
    }

    openViewRequest(schedule, day?) {
        this.store.dispatch(
            new OpenModal({
                type: 'VIEW_REQUEST',
                props: {
                    windowClass: "view-request-modal",
                    schedule: schedule,
                    equipments: this.equipments,
                    currentUser: this.currentUser,
                    filters: this.filters,
                    day: day
                },
            })
        )

    }

    filterDriver(value) {
        this.filteredRequests = this.requests.filter(e => e?.toLowerCase().includes(value.toLowerCase()))
    }

    showPopover(request) {
        this.hovededSchedule = request
        setTimeout(() => {
            this.schedulePopover.show()
        }, 100)
    }

    shouldRenderCell(index: number, request: any): boolean {
        if (index === 0) return true
        return this.days[index]?.info[request]?._id !== this.days[index - 1]?.info[request]?._id
    }

    getColspan(index: number, request: any): number {
        let count = 1
        const currentId = this.days[index]?.info[request]?._id

        for (let i = index + 1; i < this.days.length; i++) {
            if (this.days[i]?.info[request]?._id === currentId) {
                count++
            } else {
                break
            }
        }
        return count
    }

    truncateString(str) {
        if (str?.length > 18) {
          return str.slice(0, 18) + '...'
        }
        return str
      }
    

    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}



