import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'


import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects';

import { SharedModule,  CoreComponentsModule, ScheduleService, FollowUpService} from '@app/shared'
import { PipesModule } from '@app/shared/pipes';
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
import { NgxPopperjsModule } from 'ngx-popperjs'

import { SchedulesComponent } from './containers/schedules.component'
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component'
import { ScheduleFormComponent } from './components/schedule-form.component'
import { ScheduleCalendarComponent } from './components/schedule-calendar/schedule-calendar.component'
import { UrlPermissionsGuard } from '@app/core/guards'

const ScheduleRouting = RouterModule.forChild([
  {
    path: '',
    component: SchedulesComponent ,
    children: [
      {
        path: '',
        redirectTo: 'drivers',
        pathMatch: 'full'
      },
      // {
      //   path: 'trucks',
      //   component: ScheduleTruckListComponent
      // },
      // {
      //   path: 'drivers',
      //   component: ScheduleDriverListComponent
      // }
    ],
    canActivate: [UrlPermissionsGuard],
    // data: {permissionKey: 'schedule_read'}
  }
]);

@NgModule({
  declarations: [
    SchedulesComponent,
    ScheduleFormComponent,
    ScheduleListComponent,
    ScheduleCalendarComponent
  ],
  imports: [
    CommonModule,
    EffectsModule,
    StoreModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    CoreComponentsModule,
    ScheduleRouting,
    NgxDatatableModule,
    PipesModule,
    NgxPopperjsModule
  ],
  providers: [ScheduleService, UrlPermissionsGuard, FollowUpService]
})
export class ScheduleModule { }
