import { ActivatedRoute } from '@angular/router'
import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, combineLatest, Subject } from 'rxjs'
import { tap, distinctUntilChanged, withLatestFrom, takeUntil, map, filter } from 'rxjs/operators'
import { Equipment } from '@app/shared/models/equipment.model'

import { Schedule } from '@app/shared/models/schedule.model'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'


import { User } from '@app/shared/models'
import { SocketService, Candidate, getContentHTMLWithMention } from '@app/shared'
import * as fromSidebarStore from '@app/sidebar/store'
import moment from 'moment'

@Component({
  selector: 'app-schedules',
  template: `
      <div class="schedules-container">
        <app-schedule-form
          [filters]="filters"
          [types]="types"
          [statuses]="statuses"
          [total]="totalCount"
          [filtersWidthoutStatus]="filtersWidthoutStatus"
          [filtersWidthoutType]="filtersWidthoutType"
          [currentUser]="currentUser"
          (onUpdate)="setFilter($event)"
          (onClear)="clearFilter({
            subType: 'Drivers',
            viewType: filters?.viewType
          })"
        ></app-schedule-form>

        @if (filters?.viewType == 'LIST') {
          <app-schedule-list
            [schedules]="schedules"
            [filters]="filters"
            [total]="totalCount"
            [equipments]="equipments"
            [currentUser]="currentUser"
            (onUpdate)="setFilter($event)"
          ></app-schedule-list>
        } @else {
          <app-schedule-calendar
            [equipments]="equipments"
            [currentUser]="currentUser"
          ></app-schedule-calendar>
        }

      </div>
    `,
  styles: [
    `
        .schedules-container {
          padding: 20px;
        }
      `
  ]
})
export class SchedulesComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<boolean>()
  LOCAL_STORAGE_KEY = 'SCHEDULES_CALENDAR';
  statuses = []
  types = []
  totalCount = 0
  loading = false

  filters: {
    status?: String,
    startDate?: Date,
    endDate?: Date,
    startDateCalendar?: Date,
    endDateCalendar?: Date,
    dateRange?: String,
    driver?: {
      _id: String,
      name: String,
    },
    dispatcher?: {
      _id: String,
      name: String,
    },
    unit?: {
      _id: String,
      name: String,
    },
    type?: String,
    sort?: String,
    page?: Number,
    limit?: Number,
    subType?: String,
    viewType?: String
  }

  equipments = []

  filtersWidthoutStatus
  filtersWidthoutType
  currentUser: User

  schedules: Schedule[]

  constructor(
    private store: Store<fromCoreStore.State>,
    public _SocketService: SocketService,
  ) { }

  ngOnInit() {
    this.store.select(fromCoreStore.selectScheduleFilter).pipe(
      takeUntil(this.destroyed$),
      tap(filters => {
        if (!filters?.subType) {
          filters['subType'] = 'Drivers'
        }

        if (filters?.type == 'DAY_OFF') {
          filters.type = 'DAY OFF'
        }

        if (!filters?.viewType) {
          filters['viewType'] = 'LIST'
        }

        this.filters = filters

        let filter = {...this.filters};
        delete filter?.status
        delete filter?.subType
        this.filtersWidthoutStatus = filter

        let filterType = {...this.filters};
        delete filterType?.subType
        delete filterType?.type
        this.filtersWidthoutType = filterType

      })
    ).subscribe()

    this.store.select(fromCoreStore.selectAllSchedules).pipe(
      takeUntil(this.destroyed$),
      tap(schedules => {
        if (schedules) {
          // for (let item of schedules) {
          //   item.startDate = new Date(moment(item.startDate).utc().format('MM/DD/YY'))
          //   item.endDate = new Date(moment(item.endDate).utc().format('MM/DD/YY'))
          // }

          this.schedules = schedules

          this.loading = true
        }
      })
    ).subscribe()

    this.store.select(fromCoreStore.selectSchedulesTotalCount).pipe(
      takeUntil(this.destroyed$),
      tap(totalCount => {
        this.totalCount = totalCount
      })
    ).subscribe()

    this.store.select(fromCoreStore.selectSchedulesTabs).pipe(
      takeUntil(this.destroyed$),
      tap(tabs => {
        if (tabs) {
          this.statuses = tabs.statuses
          this.types = tabs.types
        }
      })
    ).subscribe()

    this.store
      .select(fromUserStore.getUser)
      .pipe(
        takeUntil(this.destroyed$),
        tap(user => {
          this.currentUser = user
        })
      )
      .subscribe()
  }

  setFilter(event) {
    let filters = {
      ...this.filters,
      ...event
    }
    this.loading = true
    this.store.dispatch(new fromCoreStore.SetSchedulesFilter(filters))
  }

  clearFilter(event) {
    this.store.dispatch(new fromCoreStore.SetSchedulesFilter({
      ...event,
      rangeType: ''
    }))
  }

  ngOnDestroy() {
    let filters = JSON.parse(window.localStorage.getItem(this.LOCAL_STORAGE_KEY))
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify({
      ...filters,
      viewType: 'LIST',
      startDateCalendar: moment().startOf('month').utcOffset(0, true).format(),
      endDateCalendar: moment().endOf('month').utcOffset(0, true).format()
    }));
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}